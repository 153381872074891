import React, { useState } from "react";
import { useForm } from "react-hook-form";

// Components

import { Form, Alert } from "react-bootstrap";
import { Button, Input } from "./../Bootstrap";
import Link from "./../../components/Link/Link";
import {
  FormContainer,
  LoginButtonContainer,
  Title,
  Description,
  FormGroupContainer,
  MainContent,
} from "../../layouts/LoginLayout/LoginLayout.styles";

import LoginLayout from "../../layouts/LoginLayout/LoginLayout";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}


const Login = (props) => {
  // States
  const [redirect, setRedirect] = useState(false);

  // Inputs States
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressAlert, setEmailAddressAlert] = useState("");
  const [emailAddressStatus, setEmailAddressStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  const pathname = props.location.pathname.substr(1);
  let entranceKey = "";
  if (pathname.indexOf(":") > -1) {
    const entranceKeyArray = pathname.split(":");
    entranceKey = entranceKeyArray[1];
  }

  // Handlers

  const onSubmitHandler = (data, e) => {
    e.preventDefault();
    setIsLoading(true);

    const newRequest = new Request("/api/subscribe", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ emailAddress: emailAddress, entranceKey: entranceKey }),
    });

    fetch(newRequest).then((response) => {
      if (('' + response.status).match(/^1|2\d\d$/)) {
        setEmailAddressAlert("Subscribed to newsletter");
        setEmailAddressStatus("success");
      } else {
        setEmailAddressAlert("Unable to subscribe");
        setEmailAddressStatus("danger");
      }
      setIsLoading(false);
    });
  };

  // Render Zone

  return (
    <LoginLayout>
      <MainContent>
        <FormContainer>
          <Title>Stay Informed</Title>
          <Description>
            To improve your presentation skills with valuable feedback check out <a href="https://presentii.com">our website</a> and subscribe for email coupons.
          </Description>
          <FormGroupContainer>
            <Form onSubmit={handleSubmit(onSubmitHandler)}>
              <Form.Group>
                <Input
                  name="emailAddress"
                  ref={register({
                    required: true,
                  })}
                  placeholder="email address"
                  defaultValue={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
                {errors.emailAddress?.type === "required" && (
                  <small className="text-danger">Email address is required</small>
                )}
              </Form.Group>
              <Form.Group className="pt-4">
                <LoginButtonContainer>
                  <Button
                    className="btn btn-md btn-primary"
                    type="submit"
                    id="submit"
                    color="primary"
                    disabled={isLoading}
                  >
                    Subscribe
                  </Button>
                </LoginButtonContainer>
              </Form.Group>
              {emailAddressAlert && (
                <Alert variant={emailAddressStatus}>
                  <p className="m-0">{emailAddressAlert}</p>
                </Alert>
              )}

              <div>
                <div className="pt-5 mb-2">
                  <Link href="/">
                    Return To Homepage
                  </Link>
                </div>
              </div>
            </Form>
          </FormGroupContainer>
        </FormContainer>
      </MainContent>
    </LoginLayout>
  );
};

export default Login;
