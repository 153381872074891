import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";

// Layout
import LoginLayout from "../../layouts/LoginLayout/LoginLayout";

//Components
import { Form, Alert } from "react-bootstrap";
import Link from "./../../components/Link/Link";
import { Input, Button } from "./../../components/Bootstrap";
import {
  FormContainer,
  LoginButtonContainer,
  Title,
  Description,
  FormGroupContainer,
  MainContent,
} from "../../layouts/LoginLayout/LoginLayout.styles";

const Login = () => {
  // States
  const [redirect, setRedirect] = useState(false);
  const [key, setKey] = useState(null);

  // Inputs States
  const [eventKey, setEventKey] = useState("");
  const [eventAlert, setEventAlert] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  // Handlers

  const onSubmitHandler = (data, e) => {
    e.preventDefault();

    setIsLoading(true);
    const newRequest = new Request("/api/enterKey", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ entranceKey: eventKey }),
    });

    fetch(newRequest).then((response) => {
      response.json().then((res) => {
        if (res.message === "direct to instructions") {
          setIsLoading(false);
          setRedirect(true);
          setKey(eventKey);
        } else if (res.message === "wrong entranceKey") {
          setEventAlert("Invalid event key.");
          setTimeout(() => {
            setEventAlert("");
            setIsLoading(false);
          }, 3000);
        } else if (res.message === "event is full") {
          setEventAlert("Event is full. No more attendees can join.");
          setTimeout(() => {
            setEventAlert("");
            setIsLoading(false);
          }, 3000);
        } else if (res.message === "event is closed") {
          setEventAlert("Event is closed.");
          setTimeout(() => {
            setEventAlert("");
            setIsLoading(false);
          }, 3000);
        }
      });
    });
  };

  // Render Zone

  if (redirect) {
    return <Redirect to={"/:" + key} />;
  }

  return (
    <LoginLayout>
      <MainContent>
        <FormContainer>
          <Title>Welcome!</Title>
          <Description>
            Enter your event key and participate. Easy to use, and no sign up
            required!
          </Description>
          <FormGroupContainer>
            <Form onSubmit={handleSubmit(onSubmitHandler)}>
              <Form.Group>
                <Input
                  name="eventKey"
                  ref={register({
                    required: true,
                  })}
                  placeholder="event key"
                  defaultValue={eventKey}
                  onChange={(e) => setEventKey(e.target.value)}
                />
                {errors.eventKey?.type === "required" && (
                  <small className="text-danger">Event key is required</small>
                )}
              </Form.Group>
              <Form.Group className="pt-4">
                {eventAlert && (
                  <Alert variant="danger">
                    <p className="m-0">{eventAlert}</p>
                  </Alert>
                )}
                <LoginButtonContainer>
                  <Button
                    className="btn btn-md btn-primary"
                    type="submit"
                    id="submit"
                    color="primary"
                    disabled={isLoading}
                  >
                    Join
                  </Button>
                </LoginButtonContainer>
              </Form.Group>

              <div>
                <div className="pt-5 mb-2">
                  <Link href={process.env.REACT_APP_ADMIN}>
                    Presenter? Click Here!
                  </Link>
                </div>
              </div>
            </Form>
          </FormGroupContainer>
        </FormContainer>
      </MainContent>
    </LoginLayout>
  );
};

export default Login;
