import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

// Components
import { Row, Col } from "react-bootstrap";
import { Container, Button } from "./../Bootstrap";
import EventLogo from "../EventLogo/EventLogo";
import EventDetails from "./../EventDetails/EventDetails";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}
var pollingInterval;
export default class Instruction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      haveAccess: false,
      redirect: false,
      redirectToLanding: false,
      eventStatus: "",
      eventTitle: "",
      presenterName: "",
      description: "",
      logo: "",
      key: "",
    };
  };
  isEventOpen = (entranceKey) => {
    const newRequest = new Request(myServer + "/api/isEventOn", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({entranceKey: entranceKey}),
    });
    fetch(newRequest)
      .then((response) => {
        response.json().then((res) => {
          if (res.statusCode < 300) {
            this.enterEvent();
          } else if (res.statusCode === 425) {
            this.state.eventStatus = "lobby";
          } else if (res.statusCode === 403) {
            this.state.eventStatus = "open";
          } else if (res.statusCode === 404) {
            this.state.eventStatus = "closed";
            pollingInterval = null;
          } else {
            console.log(res.statusMessage);
          }
        });
      })
      .catch((error) => {
        return error;
      });
  };
  enterEvent = () => {
    const newRequest = new Request(myServer + "/api/EnterEvent", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      // body: JSON.stringify({eventID: eventID}),
    });
    fetch(newRequest)
      .then((response) => {
        response.json().then((res) => {
          this.getQuestions();
        });
      })
      .catch((error) => {
        return error;
      });
  };
  getQuestions = () => {
    let self = this;
    const newRequest = new Request(myServer + "/api/getQuestions", {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });
    fetch(newRequest).then(function (response) {
      response
        .json()
        .then((res) => {
          if (res.data.length === 0) {
            localStorage.setItem("Questions", []);

            self.setState({
              redirect: true,
            });
          } else {
            var copy = res.data.slice(0);
            //find matching ids
            var result = copy.reduce(
              (acc, o) => (
                (acc[o.questionID] = (acc[o.questionID] || 0) + 1), acc
              ),
              {}
            );

            var numOfQuestions = Object.keys(result).length;
            var optionsArray = [];

            var pages = new Array(numOfQuestions);

            for (let j = 0; j < numOfQuestions; j++) {
              for (let i = 0; i < copy.length; i++) {
                // WARNING: Don't refactor this comparison, they are not the same type, this only look to compare the same value !!!!!!!!!!
                if (copy[i].questionID == Object.keys(result)[j]) {
                  // END OF WARNING
                  var type;
                  if (copy[i].type === "Multiple Choice") {
                    type = "radiogroup";
                  } else {
                    type = "checkbox";
                  }
                  pages[j] = {
                    name: "page" + j,
                    questions: [
                      {
                        name: JSON.stringify(copy[i].questionID),
                        title: copy[i].questionText,
                        type: type,
                        isRequired: true,
                        choices: [],
                      },
                    ],
                  };

                  optionsArray.push(copy[i].optionText);
                }
              }

              Object.assign(pages[j].questions[0].choices, optionsArray);

              optionsArray = [];
            }
            optionsArray = [];

            var JSONarray = JSON.stringify(pages);
            localStorage.setItem("Questions", JSONarray);

            self.setState({
              redirect: true,
            });
          }
        })
        .catch((error) => {
          return error;
        });
    });
  };

  newEntranceKey = (key) => {
    let self = this;
    const newRequest = new Request(myServer + "/api/enterKey", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ entranceKey: key }),
    });

    fetch(newRequest).then((response) => {
      response.json().then((res) => {
        if (res.message === "direct to instructions") {
          this.setState({
            description: res.eventDesc,
            key: key,
          });
        } else if (
          res.message === "wrong entranceKey" ||
          res.message === "event is closed"
        ) {
          self.setState({
            redirectToLanding: true,
          });
        }
      });
    });
  };
  async componentDidMount() {
    let self = this;
    let pathname = this.props.location.pathname.substr(1);

    let entranceKey = pathname.split(":");

    if (document.cookie && document.cookie.indexOf("session") !== -1) {
      //need to check that the key is not a new one

      this.isEventOpen(entranceKey[1]);

      const checkEvent = new Request(myServer + "/api/checkEventKey", {
        method: "GET",
        credentials: "include",
        headers: new Headers({
          "Content-Type": "application/json",
          entranceKey: entranceKey[1],
        }),
      });
      await fetch(checkEvent).then((answer) => {
        answer.json().then((data) => {
          if (data.verdict === true) {
            const newRequest = new Request(myServer + "/api/getDescription", {
              method: "GET",
              credentials: "include",
              headers: new Headers({
                "Content-Type": "application/json",
                entranceKey: entranceKey[1],
              }),
            });
            fetch(newRequest).then((response) => {
              response.json().then((res) => {
                if (res.message === "success") {
                  let adminServer = process.env.REACT_APP_ADMIN
                  let logo;
                  if (res.logo !== 'image_placeholder.png' && res.logo !== '') {
                    const companyID = res.companyID;
                    logo = `${adminServer}/Uploads/Users/${companyID}/images/${res.logo}`;
                  }
                  this.setState({
                    presenterName: res.presenterName,
                    eventTitle: res.eventTitle,
                    description: res.eventDesc,
                    key: entranceKey[1],
                    logo,
                  });
                  return true;
                }
              });
            });
          } else {
            self.newEntranceKey(entranceKey[1]);
          }
        });
      });
    } else {
      this.newEntranceKey(entranceKey[1]);
    }

    pollingInterval = setInterval(async function () {
      const newRequest = new Request(myServer + "/api/isEventOn", {
        method: "POST",
        credentials: "include",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({entranceKey: entranceKey[1]}),
      });
      fetch(newRequest)
        .then((response) => {
          response.json().then((res) => {
            if (res.statusCode === 200) {
              self.enterEvent();
            }
          });
        })
        .catch((error) => {
          return error;
        });
    }, 10000);
  }

  render() {
    if (this.state.redirectToLanding) {
      return <Redirect to={"/"} />;
    }
    if (this.state.redirect) {
      return <Redirect to={this.props.match.url + "/slider"} />;
    }
    const logo = this.state.logo;
    const size = 40;
    let buttonText = "go";
    if (this.state.eventStatus === "closed") {
      buttonText = "event finished";
    } else if (this.state.eventStatus === "open") {
      buttonText = "waiting for host";
    } else if (this.state.eventStatus === "lobby") {
      buttonText = "event not ready";
    }

    return (
      <DefaultLayout>
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              {this.state.key !== "" && <EventLogo />}
            </Col>
            <Col
              xs={12}
              lg={6}
              className="my-5 my-lg-0 text-center d-lg-flex flex-column align-items-center justify-content-center "
            >
              <EventDetails
                title={this.state.eventTitle}
                presenterName={this.state.presenterName}
              />
              <p>{this.state.description}</p>
              <div className="d-flex justify-content-center">
                {this.state.logo != null && <img alt="logo" src={logo} width={`${size}`} height={`${size}`}  style={{ marginRight: size / 2 }} />}
                <Button className="btn btn-md btn-primary" onClick={this.enterEvent} color="primary" disabled>
                  {buttonText}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </DefaultLayout>
    );
  }
}
